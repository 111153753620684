import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import "../../assets/css/Counselling.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import VideoPlayer from "react-background-video-player";
import {
	Indvisual_Counselling,
	Couple_Relationship_counselling,
	Group_counselling,
	Family_counselling,
	Life_counselling,
	Dating_counselling,
} from "../../constant";
import { CouncelApi, homeApi } from "../../Redux/Auth/Auth_Action";

function Counselling() {
	const [homestate, setHomestate] = useState();
	const [state, setState] = useState(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const ConunselFunc = () => {
		dispatch(CouncelApi())
			.then((res) => {
				setState(res?.data?.response?.data);
				// console.log(res?.data?.response?.data, 'ssssssssssssssssssssssssssssssss');
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		ConunselFunc();
		dispatch(homeApi())
			.then((res) => {
				// console.log(res.data.response.data);
				setHomestate(res.data.response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	return (
		<div className="Counselling_Page ">
			<section className="common-banner">
				<Header />
				<VideoPlayer
					className="video"
					src={`${homestate?.image_url}${homestate?.video}`}
					autoPlay={true}
					muted={true}
					preload="auto"
				/>
				<div className="container">
					<div className="url-container">
						<h2 className="title">Counseling and Education Opportunities</h2>
						{/* <h2 className="title"></h2> */}
					</div>
				</div>
			</section>

			{/* <!-- Counselling Sec Start Here --> */}
			{state?.map((item, index) => {
				if (index === 3) {
					return (
						<section key={index} className="family-Counselling">
							<div className="container">
								<div className="row family-Counselling-bg">
									<div className="col-lg-6">
										<div className="img-control">
											<img
												src={`${item?.image_url}${item?.image}`}
												alt="img"
												className="image-fluid"
												loading="lazy"
											/>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="family-Counselling-text">
											<h2 className="family-main-text">{item?.title}</h2>
											<p className="family-paragraph">{item?.description}</p>
											{/* <button onClick={() => { navigate('/book') }} className="btn bt-wdht-fixed py-2">Book An Appointment</button> */}
										</div>
									</div>
								</div>
							</div>
						</section>
					);
				}
				if (index % 2 == 0) {
					return (
						<section key={index} className="Counselling-indvisual">
							<div className="container">
								<div className="row">
									<div className="col-lg-6">
										<div className="indvisual-text">
											<h2 className="indvisual-main-text">{item?.title}</h2>
											<p className="indvisual-paragraph">{item?.description}</p>
											{/* <button onClick={() => { navigate('/book') }} className="btn bt-wdht-fixed py-2">Book An Appointment</button> */}
										</div>
									</div>
									<div className="col-lg-6">
										<div className="img-control">
											<img
												src={`${item?.image_url}${item?.image}`}
												alt="img"
												className="image-fluid"
												loading="lazy"
											/>
										</div>
									</div>
								</div>
							</div>
						</section>
					);
				} else {
					return (
						<section key={index} className="couple-relationship">
							<div className="container">
								<div className="row">
									<div className="col-lg-6">
										<div className="img-control">
											<img
												src={`${item?.image_url}${item?.image}`}
												alt="img"
												className="image-fluid"
												loading="lazy"
											/>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="indvisual-text">
											<h2 className="Relationship-main-text">{item?.title}</h2>
											<p className="Relationship-paragraph">
												{item?.description}
											</p>
											{/* <button onClick={() => { navigate('/book') }} className="btn bt-wdht-fixed py-2">Book An Appointment</button> */}
										</div>
									</div>
									<div className="line-couple-relationship"></div>
								</div>
							</div>
						</section>
					);
				}
			})}

			{/* <!--Counslling Sec End Here --> */}
			<div className="my-5"></div>
			<Footer />
		</div>
	);
}

export default Counselling;
