import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import Profile from "../../pages/Profile/Profile";
import "../../assets/css/BookAnAppoinment.css";
import { ProfileImage } from "../../constant";

function SideBar(props) {
  const selector = useSelector((state) => state.Auth.user);
  let currentUrl = window.location.href.split("/");
  // console.log("currentUrl", currentUrl);
  const dispatch = useDispatch();

  const Logout = () => {
    dispatch({ type: "DEL_TOKEN" });
  }

  return (
    <>
      <div className="col-lg-3">
        <div className="sidebar-head">
          <p>My Account</p>
        </div>
        <div className="profile-tabs-wrapper">
          <div className="img-and-username-wrapper">
            <div className="profile-img">
              <figure>
                {/* {uploadLoading ? (
                  <img src={fileupload} className="img-fluid" alt="" />
                ) : (
                  <img
                    src={`${fileupload ? fileupload : Profile}`}
                    className="img-fluid"
                    alt=""
                  />
                )} */}

                <img src={`${selector?.image_url}${selector?.profile_picture}`} alt="img" loading="lazy" className="image-fluid" />
              </figure>
            </div>
            <div className="username mt-4">
              <h3 className="username">{selector?.first_name} {selector?.last_name}</h3>
              <h3 className="username">{selector?.email}</h3>
            </div>
          </div>
          <div className="profile-links-wrapper">
            <div
              className="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <Link
                to="/profile"
                className={`${currentUrl?.[3] === "profile" ? "nav-link active" : "nav-link"
                  }`}
              >
                My Profile
              </Link>
              {/* <Link
                to="/my-appoinment"
                className={`${
                  currentUrl?.[3] === "my-appoinment"
                    ? "nav-link active"
                    : "nav-link"
                }`}
              >
                My Appointments
              </Link> */}
              <ul className="navbar-nav ">
                <Link
                  to="/my-appoinment"
                  className={`${currentUrl?.[3] === "my-appoinment"
                    ? "nav-link active"
                    : "nav-link"
                    }`}
                >
                  My Appointments
                </Link>

                <i className="fe fe-clipboard" />
                {/* <li className="nav-item">
                  <div className="collapse " id="sidebarBasics">
                    <ul className="nav nav-sm flex-column">
                      <li className="nav-item">
                        <Link
                          to="/my-appoinment"
                          className={`${currentUrl?.[3] === "my-appoinment"
                            ? "nav-link active"
                            : "nav-link"
                            }`}
                        >
                          Past Sessions
                          <i className="fe fe-clipboard" />
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/my-appoinment"
                          className={`${currentUrl?.[3] === "my-appoinment"
                            ? "nav-link active"
                            : "nav-link"
                            }`}
                        >
                          Upcoming Sessions
                          <i className="fe fe-clipboard" />
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/my-appoinment"
                          className={`${currentUrl?.[3] === "my-appoinment"
                            ? "nav-link active"
                            : "nav-link"
                            }`}
                        >
                          Canceled Sessions
                          <i className="fe fe-clipboard" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li> */}
              </ul>
              <Link
                to="/billing-details"
                className={`${currentUrl?.[3] === "billing-details"
                  ? "nav-link active"
                  : "nav-link"
                  }`}
              >
                Billing Details
              </Link>
              <Link
                to="/my-review"
                className={`${currentUrl?.[3] === "my-review"
                  ? "nav-link active"
                  : "nav-link"
                  }`}
              >
                Give Reviews
              </Link>

              <Link
                to="/"
                onClick={() => { Logout() }}
                className={`${currentUrl?.[3] === "address" ? "nav-link active" : "nav-link"
                  }`}
              >
                Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
