import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import "../../assets/css/Home.css";
import Footer from "../../components/Footer/Footer";
import TestimonialSlider from "../../components/TestimonialSlider/TestimonialSlider";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import {
	homeAbout,
	icon1,
	icon2,
	icon3,
	icon4,
	playbtn,
	service1,
	service2,
	service3,
	videoPreview,
	AboutUsImage,
	CourceLogo,
	AboutUsDialog,
	SliderBtn1,
	SliderBtn2,
} from "../../constant";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { homeApi, ServicesGetAllApi } from "../../Redux/Auth/Auth_Action";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoPlayer from "react-background-video-player";
const Home = () => {
	const navigate = useNavigate();
	const [state, setState] = useState(null);
	const siteSetting = useSelector((state) => state.Auth.siteSetting);
	const [services, setService] = useState(null);
	const dispatch = useDispatch();
	const HomeApiFunc = () => {
		dispatch(homeApi())
			.then((res) => {
				// console.log(res.data.response.data);
				setState(res.data.response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const ServiceApiFunc = () => {
		dispatch(ServicesGetAllApi())
			.then((res) => {
				console.log(res.data.response.data.data);
				setService(res.data.response.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		HomeApiFunc();
		ServiceApiFunc();
		window.scrollTo(0, 0);
	}, []);

	const serviceData = [
		{
			id: 1,
			img: service1,
		},
		{
			id: 2,
			img: service2,
		},
		{
			id: 3,
			img: service3,
		},
	];
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
	};
	return (
		<div className="Home_Page">
			<VideoPlayer
				className="video"
				src={`${state?.image_url}${state?.video}`}
				autoPlay={true}
				muted={true}
				preload="auto"
			/>
			<section className="home-banner">
				<Header />
				<div className="container">
					<div className="top_content">
						<ul className="social-links1 ">
							<li>
								<div
									onClick={() => {
										window.open(`${siteSetting?.facebook}`, `_blank`);
									}}
									target="_blank"
									rel="noopener noreferrer"
									className="social-icon-color"
								>
									<i className="fa fa-facebook cursor_pointer"></i>
								</div>
							</li>
							<li>
								<div
									onClick={() => {
										window.open(`${siteSetting?.twitter}`, `_blank`);
									}}
									target="_blank"
									rel="noopener noreferrer"
									className="social-icon-color"
								>
									<i className="fa fa-twitter cursor_pointer"></i>
								</div>
							</li>
							<li>
								<div
									onClick={() => {
										window.open(`${siteSetting?.instagram}`, `_blank`);
									}}
									target="_blank"
									rel="noopener noreferrer"
									className="social-icon-color"
								>
									<i className="fa fa-instagram cursor_pointer"></i>
								</div>
							</li>
							<li>
								<div
									onClick={() => {
										window.open(`${siteSetting?.linkedin}`, `_blank`);
									}}
									target="_blank"
									rel="noopener noreferrer"
									className="social-icon-color"
								>
									<i className="fa fa-linkedin cursor_pointer"></i>
								</div>
							</li>
						</ul>

						<div className="row">
							<div className="col-lg-7 ">
								<div className="content-container">
									<p className="home-sm-title">
										Welcome To Dream Builders Counseling
									</p>
									<h2 className="home-bd-title">{state?.title}</h2>
									{/* <h2 className="home-bd-title">I Can Help.</h2> */}
									<p className="home-sm-para">
										<div
											dangerouslySetInnerHTML={{ __html: state?.description }}
										/>
									</p>
									<div>
										<button
											onClick={() => {
												navigate("/book");
											}}
											className="btn"
										>
											Book An Appointment
										</button>
										{/* <button onCLick={() => { navigate('/book') }} className="banner_btn btn">Book An Appointment</button> */}
									</div>
								</div>
							</div>
							<div className="col-lg-4"></div>
						</div>
					</div>
				</div>
			</section>
			<div className="about-us-background-image"></div>
			<section className="home-about">
				<div className="container">
					<div className="row about-shadow">
						<div className="col-lg-6 pos-rel pl-0">
							<div className="img-control">
								<img
									src={`${state?.image_url}${state?.about_image}`}
									alt="img"
									loading="lazy"
									className="image-fluid"
								/>
								<div className="text-block">
									<div className="row">
										<div className="col-4">
											<img src={CourceLogo} loading="lazy" alt="img" className="course-image" />
										</div>
										<div className="col-8">
											<div className="course-text-main">
												<h4 className="course-text-1">Courses Available</h4>
												<p className="course-text-2">
													{state?.courses}
													<sup>+</sup>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="about-us-image-container">
								<img src={AboutUsImage} alt="img" loading="lazy" className="about-us-image-img" />
								<div className="about-us-image">
									<p className="years-of-experience mr-2">
										{state?.experience}
										<sup>+</sup>{" "}
									</p>
									<h4 className="years-of-experience">
										Years of <br /> Experience{" "}
									</h4>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div>
								<p className="tags-title">About us</p>
								<h2 className="title-second-main">{state?.about_title}</h2>
								<div
									className="para"
									dangerouslySetInnerHTML={{ __html: state?.about_description }}
								></div>
								<div className="pos-rel">
									<img
										src={AboutUsDialog}
										alt="img"
										loading="lazy"
										className="about-us-dialog-img img_dsp_nun"
									/>
									<p className="about-us-dialog-text">{state?.about_us}</p>
								</div>
								{/* <p className="points">
                  <IoMdCheckmarkCircleOutline className="home-icon" />
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
                <p className="points">
                  <IoMdCheckmarkCircleOutline className="home-icon" />
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting.
                </p>
                <p className="points">
                  <IoMdCheckmarkCircleOutline className="home-icon" />
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting
                </p> */}
								<div
									dangerouslySetInnerHTML={{ __html: state?.about_content }}
								></div>

								<div className="btn-container">
									<button
										onClick={() => {
											navigate("/about-us");
										}}
										className="btn  mr-4"
									>
										Read More
									</button>
									<button
										onClick={() => {
											navigate("/book");
										}}
										className="btn btn-trans "
									>
										Book An Appointment
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="home-service">
				<div className="container">
					<p className="sm-tag head_home_all_same_small">Services</p>
					{/* <h2 className="title-service-main head_home_all_same">
						Services We Provide For Our Clients
					</h2> */}
					<h2 className="title-service-main head_home_all_same">
						Heal Your Broken Relationships
					</h2>
					{/* <div className="row card-row"> */}
						<Slider {...settings}>
							{services?.slice(0, 8).map((item, index) => {
								return (
									<div  key={index + 333}>
										<div className="service-card">
											<div className="img-box">
												<img
													src={`${item?.image_url}${item?.image}`}
													alt="img "
													className="image-fluid"
													loading="lazy"
												/>
											</div>
											<p className="service-name mt-3 ml-2 mb-1">{item.name}</p>
											<div className="price-cont mb-3">
												<p className="service-price ml-2">$ {item.price}</p>
												<Link to="/book">
													<div className="pos-rel d-flex">
														<u className="book-now-service">Book Now</u>
														<div className="right_arrow_serviceRates">
															<i
																className=" fa fa-arrow-right"
																aria-hidden="true"
															></i>
														</div>
													</div>
												</Link>
												{/* <Link style={{ textDecoration: "underline" }} to="/book" className="service-name">
                        Book Now
                      </Link> */}
											</div>
										</div>
									</div>
								);
							})}
						</Slider>
					{/* </div> */}
				</div>
			</section>

			<section className="home-client">
				<div className="container">
					<p className="tag-sm head_home_all_same_small">Video</p>
					<h2 className="title mb-5">
						Our Counselling and Mental Wellness Agency <br />
						Is Here to Mend the Broken Relations in Your Life
					</h2>

					<div className="row">
						<div className="col-lg-3 col-md-6">
							<div style={{ backgroundColor: "#8e6550" }}>
								<h5 className="home_browbox_headingss">Our Clients</h5>
								<div className="mile-box">
									<div className="icon_box">
										<figure>
											<img
												src={icon1}
												className="img-fluid max_width_icon_home"
												alt="img"
												loading="lazy"
											/>
										</figure>
									</div>
									<div className="content_box">
										<h3>
											<span className="count">{state?.clients}</span>
											<span className="symbol">+</span>
										</h3>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div style={{ backgroundColor: "#8e6550" }}>
								<h5 className="home_browbox_headingss">Success Stories</h5>
								<div className="mile-box">
									<div className="icon_box">
										<figure>
											<img
												src={icon2}
												className="img-fluid max_width_icon_home"
												alt="img"
												loading="lazy"
											/>
										</figure>
									</div>
									<div className="content_box">
										<h3>
											<span className="count">{state?.stories}</span>
											<span className="symbol">+</span>
										</h3>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div style={{ backgroundColor: "#8e6550" }}>
								<h5 className="home_browbox_headingss">Gradual Improvement</h5>
								<div className="mile-box">
									<div className="icon_box">
										<figure>
											<img
												src={icon3}
												className="img-fluid max_width_icon_home"
												alt="img"
												loading="lazy"
											/>
										</figure>
									</div>
									<div className="content_box">
										<h3>
											<span className="count">{state?.gradual}</span>
											<span className="symbol">%</span>
										</h3>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div style={{ backgroundColor: "#8e6550" }}>
								<h5 className="home_browbox_headingss">Clients Reviews</h5>
								<div className="mile-box">
									<div className="icon_box">
										<figure>
											<img
												src={icon4}
												className="img-fluid max_width_icon_home"
												alt="img"
												loading="lazy"
											/>
										</figure>
									</div>
									<div className="content_box">
										<h3>
											<span className="count">{state?.reviews}</span>
											<span className="symbol">+</span>
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="video_sec">
				<div className="container">
					<div className="preview_img">
						<video
							width={"100%"}
							src={`${state?.image_url}${state?.video}`}
							controls
						>
							Your browser does not support the video tag.
						</video>
						{/* <figure>
              <img src={videoPreview} className="img-fluid" />
            </figure> */}
					</div>
					{/* <div className="ply-btn">
            <a href="#">
              <figure>
                <img src={playbtn} className="img-fluid" />
              </figure>
            </a>
          </div> */}
				</div>
			</section>

			{/* <!-- testimonials starts here --> */}
			<section className="testimonails">
				<div className="container">
					<div className="row pb-4 ">
						<div className="col-lg-3"></div>
						<div
							className="col-lg-6"
							data-aos="fade-up"
							data-aos-anchor-placement="center-bottom"
							data-aos-offset="300"
							data-aos-easing="ease-in-sine"
						>
							<div className="content-wrapper text-center">
								<p className="testmonials head_home_all_same_small">
									Testimonials{" "}
								</p>
								<h2 className="head_home_all_same">Our Reviews</h2>
								<p>
									We are proud and happy to share what our customers are
									experiencing by working with us.
								</p>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
					<div className="row review-bg">
						<div className="col-lg-1 ">
							{/* <figure>
                <img src={SliderBtn1} className="video-image-btn" />
              </figure> */}
						</div>
						<div className="col-lg-10">
							<div
								className="testimonails-slider"
								data-aos="fade-up"
								data-aos-anchor-placement="center-bottom"
								data-aos-offset="350"
								data-aos-easing="ease-in-sine"
							>
								<TestimonialSlider />
							</div>
						</div>
						<div className="col-lg-1">
							{/* <figure>
                <img src={SliderBtn2} className="video-image-btn" />
              </figure> */}
						</div>
					</div>
				</div>
			</section>
			{/* <!-- testimonials ends here --> */}

			<Footer />
		</div>
	);
};

export default Home;
