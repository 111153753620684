
export const headers = () => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
}

export const headersWithToken = (token) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
}

export const headersWithImage = (token) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*'
        }
    }
}

export const responseError = (e) => {
    let data = [];
    if (e.response.data.statusCode === 401) {
        data.push({ message: e.response.data.message, color: 'warning' });
    }

    else if (e.response.data.statusCode === 409) {
        e.response.data.errors.map((val) => {
            data.push({ message: val, color: 'warning' });
        });
    }

    else if (e.response.data.statusCode === 422 || e.response.data.statusCode === 405) {
        data.push({ message: e.response.data.errors, color: 'warning' });
    }

    else if (e.response.data.statusCode === 400 && e.response.data.errors) {
        for (let key in e.response.data.errors) {
            if (e.response.data.errors.hasOwnProperty(key)) {
                data.push({
                    message: e.response.data.errors[key][0],
                    color: 'warning'
                });
            }
        }
    }

    else if (e.response.data.statusCode === 404) {
        data.push({ message: e.message, color: 'info' });
    }

    else {
        data.push({ message: e.message, color: 'danger' });
    }
    return data;
}

export const site_url = 'https://admin.dreambuilderscounseling.com';
export const Login = `${site_url}/api/auth/login`;
export const SignUp = `${site_url}/api/auth/store`;
export const ForgotPasswordUrl = `${site_url}/api/auth/forgot-password`;
export const ResetPasswordUrl = `${site_url}/api/auth/reset-password`;
export const AboutUsUrl = `${site_url}/api/setting/about-us`;
export const PrivacyPolicyUrl = `${site_url}/api/pages/get-pages`;
export const FaqUrl = `${site_url}/api/faq/get-faqs`;
export const HomePageUrl = `${site_url}/api/setting/home-section`;
export const ContactUsUrl = `${site_url}/api/contactStore`;
export const VideosUrl = `${site_url}/api/videos/get-videos`;
export const SiteSettingUrl = `${site_url}/api/setting/get-settings`;
export const NewsLetterUrl = `${site_url}/api/news-letter-store`;
export const ServicesGetAllUrl = `${site_url}/api/services/get-services`;
export const CouncelUrl = `${site_url}/api/counselling/get-counselling`;
export const ProfileUpdate = `${site_url}/api/user/update`;
export const ChangePasswordUrl = `${site_url}/api/user/changePassword`;
export const PostReviewUrl = `${site_url}/api/rating/store-rating`;
export const GetUpcomingBookingsUrl = `${site_url}/api/appointments/get-upcoming`;
export const GetPreviousBookingsUrl = `${site_url}/api/appointments/get-past`;
export const GetCanceledBookingsUrl = `${site_url}/api/appointments/get-cancelled`;
export const UploadFileFormUrl = `${site_url}/api/appointments/upload-file`;
export const CancelAppointmentUrl = `${site_url}/api/appointments/update-status`;
export const BlogUrl = `${site_url}/api/blogs/get-blog`;
export const BlogDetails = `${site_url}/api/blogs/store-blog`;
export const AppointmentServiceUrl = `${site_url}/api/services/get-services`;
export const AppointmentTypeUrl = `${site_url}/api/setting/get-types`;
export const StoreAppointmentUrl = `${site_url}/api/appointments/create`;
export const TestimonailApiUrl = `${site_url}/api/rating/get-rating`;
export const InfoAfterLogin = `${site_url}/api/user/info`;
export const GetDefaultCardUrl = `${site_url}/api/cards`;
export const updateDefaultCardUrl = `${site_url}/api/cards/make/default`;
export const DeleteCardUrl = `${site_url}/api/cards/delete`;
export const AddCardUrl = `${site_url}/api/cards/create`;
