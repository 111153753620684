import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "../../assets/css/Login.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { ResetPasswordImage } from "../../constant";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import { ResetPasswordApi } from "../../Redux/Auth/Auth_Action";

function ResetPassword() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [state, setState] = useState({
    newPass: undefined,
    ConfirmnewPass: undefined,
    email: undefined,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  let token_url = searchParams.get("token");

  const InputVal = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  }

  const ResetFunction = (e) => {
    e.preventDefault();
    let data = {
      email: state.email,
      reset_token: token_url,
      password: state.newPass,
      confirm_password: state.ConfirmnewPass,
    }
    if (data.password === data.confirm_password) {
      dispatch((ResetPasswordApi(data)))
        .then((res) => {
          // console.log(res);
          toast.success("Password changed successfully");
          setTimeout(() => {
            navigate("/signin");
          }, 4000);
        })
        .catch((err) => {
          console.log(err);
        })
    } else {
      toast.error('Password and confirm password is not matched');
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="common-banner">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">Forgot Password?</h2>
          </div>
        </div>
      </section>

      <section className="login">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="card">
                <div className="card-body">
                  <div className="login-inner">
                    <div className="preview_img">
                      <figure>
                        <img src={ResetPasswordImage} className="img-fluid" alt="img" loading="lazy"/>
                      </figure>
                    </div>
                    <p className="forget-title">Password Change</p>
                    {/* <p className="forget-main-para">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    </p> */}
                    <div className="field-set">
                      <form onSubmit={ResetFunction}>
                        <div className="row">
                          <div className="reset-password-container">
                            <div className="col-lg-12 reset-input-cont2">
                              <p className="label">New Password</p>
                              <input value={state.newPass} name="newPass" onChange={InputVal} type="password" placeholder="********" />
                            </div>

                            <div className="col-lg-12 reset-input-cont2">
                              <p className="label">Confirm Password</p>
                              <input value={state.ConfirmnewPass} name="ConfirmnewPass" onChange={InputVal} type="password" placeholder="********" />
                            </div>
                            <div className="reset-password-btn">
                              <button type="submit" className="btn reset-btn">
                                Save Password
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ResetPassword;
