import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../../assets/css/Header.css";
import { logo } from "../../constant";
// import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import SwipHeader from "./SwipHeader";
import bavatar from '../../assets/img/bavatar.png';

function Header(props) {
  const { bgColor } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const selector = useSelector((state) => state.Auth);
  const reduxState = useSelector((state) => state);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  let currentUrl = window.location.href.split("/");
  const [mobView, setMobView] = useState(false);
  const [loading, setLoading] = useState(false);
  const NavBarHandler = () => {
    setMobView(!mobView);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log(selector.siteSetting?.logo,'xzxxxxxxxxxxxxxxxxxxxx');

  const HeaderLinks = [
    { name: "Home", link: "/" },
    { name: "About Us", link: "/about-us" },
    { name: "Services & Rates", link: "/services" },
    { name: "Blogs", link: "/blogs" },
    { name: "Opportunities", link: "/opportunities" },
    { name: "Videos", link: "/videos" },
    { name: "Contact Us", link: "/contact" },
    { name: "Appointment", link: "/book" },

  ];

  const LogoutFunc = () => {
    dispatch({ type: "DEL_TOKEN" });
  }

  return (
    <header>
      <div className={`${bgColor ? "main-header back-header" : "main-header"}`}>
        <div className="container resposive_full_width_header">
          <div className="row header-full">
            <div className="flex_container">

              <div className="Logo_Part">
                <div className="logoDv">
                  <Link to="/">
                    <img loading="lazy" src={`${selector.siteSetting?.form_url}${selector.siteSetting?.logo}`} className="img-fluid" alt="img" />
                  </Link>
                </div>
              </div>

              <div className="All_List_Part">
                <div className="navbarMenu">
                  <ul className="navItems ">
                    <li className="menu-items">
                      <Link
                        to="/"
                        className={`${currentUrl?.[3] === "" ? "active" : null}`}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/about-us"
                        className={`${currentUrl?.[3] === "about-us" ? "active" : null
                          }`}
                      >
                        About us
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/services"
                        className={`${currentUrl?.[3] === "services" ? "active" : null
                          }`}
                      >
                        Services & Rates
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/blogs"
                        className={`${currentUrl?.[3] === "blogs" ? "active" : null
                          }`}
                      >
                        Blogs
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/opportunities"
                        className={`${currentUrl?.[3] === "opportunities" ? "active" : null
                          }`}
                      >
                        Opportunities
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/videos"
                        className={`${currentUrl?.[3] === "videos" ? "active" : null
                          }`}
                      >
                        Videos
                      </Link>
                    </li>

                    <li className="menu-items">
                      <Link
                        to="/contact"
                        className={`${currentUrl?.[3] === "contact" ? "active" : null
                          }`}
                      >
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bookAppointment_Part">
                <div className="d-flex justify-space-between">

                  <div className="avatr_account_profile">
                    <Button
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onMouseEnter={handleClick}
                      className="cursor_pointer"
                    >
                      {
                        selector?.token
                          ?
                          <>
                            <img onClick={() => { navigate('/profile') }} loading="lazy" height={40} width={40} className="profile_image_header center_account_prof_ico mr-2" alt="img" src={`${selector?.user?.image_url}${selector?.user?.profile_picture}`} />
                            <span className="center_account_prof_ico d-flex">{selector?.user?.first_name} <i className="fa fa-angle-down ml-2 mt-1"></i> </span>
                          </>
                          :
                          <>
                            <img loading="lazy" onClick={() => { navigate('/profile') }} alt="img" height={40} width={40} className="profile_image_header center_account_prof_ico mr-2" src={bavatar} />
                            <span className="center_account_prof_ico d-flex">Account <i className="fa fa-angle-down ml-2 mt-1"></i> </span>
                          </>
                      }

                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <div
                        className="menu_header_dropdown_head">
                        {
                          selector?.token
                            ?
                            <>
                              <p onClick={() => { navigate('/profile') }} className="center_account_prof_ico mb-2 porf_drop_menu">Profile</p>
                              {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                              <p onClick={LogoutFunc}
                                className="center_account_prof_ico mb-1 porf_drop_menu">Logout</p>
                            </>

                            :
                            <>
                              <p onClick={() => { navigate('/signup') }} className="center_account_prof_ico mb-2 porf_drop_menu">Register</p>
                              {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                              <p onClick={() => { navigate('/login') }}
                                className="center_account_prof_ico mb-1 porf_drop_menu">Login</p>
                            </>
                        }
                      </div>
                    </Menu>
                  </div>
                  {/* :
                      <div className="d-flex header_signin_signup">
                        <p onClick={() => { navigate('/login') }} className="mr-set_prof">
                          Sign In
                        </p>
                        <p onClick={() => { navigate('/signup') }} className="mr-set_prof">
                          Sign Up
                        </p>
                      </div>
                  } */}
                  <div className="menu-items ml-3">
                    {/* menu-btn */}

                    <button onClick={() => { navigate('/book') }} className="btn py-2">Appointment</button>
                    {/* <div className="button-group">
                    <Link
                      to="/book"
                      className="book-an-appoinment"
                    >
                      Book An Appoinment
                    </Link>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- mobile header start --> */}
          <div className='header-small'>
            <div className='bg-red-hdr'>
              <div className=''>

                <div className='d-flex align-center'>
                  <Link to="/">
                    <img onClick={() => { navigate('/') }} className=' img_respon py-2 px-0'  loading="lazy" src={logo} alt='logo' />
                  </Link>
                </div>
              </div>
              <div className='d-flex'>
                <div className='align-center'>
                  {(reduxState.Auth.token) ?
                    <Link className='f-pop-reg home_link mr-2 ico_pos-rel_headd' to="/profile">
                      <img className="profile_img_header mt-2"
                        src={`${selector?.user?.image_url}${selector?.user?.profile_picture}`}  alt="img" loading="lazy"/>

                    </Link>
                    :
                    <div>
                      {
                        (reduxState.Auth.token) ?
                          <>
                            <div className='d-flex align-item-center'>
                              <span className='f-mon-semibold text-white f-16 mr-3'>
                                <span className='f-mon-light mr-2'>Hi!
                                </span>
                                {`${reduxState?.Auth?.user?.first_name}`}
                              </span>
                              <span>
                                <img onClick={() => { navigate("/profile") }} className="profile_img_header"
                                  src={reduxState?.Auth?.user?.profile_picture} alt="img" loading="lazy"
                                />
                              </span>
                              {/* <button
                                onClick={() => { navigate("/book") }}
                                className='btn btn-light f-16 f-mon-reg btn-swip-hhdr '>
                                Appoinment
                              </button> */}
                              <img onClick={() => { navigate("/profile") }} className="profile_img_header"
                                src={reduxState?.Auth?.user?.profile_picture} alt="img" loading="lazy"
                              />
                            </div>
                          </>
                          :
                          <>
                            <img onClick={() => { navigate("/profile") }} className="profile_img_header"
                              src={bavatar} alt="img" loading="lazy"
                            />
                            {/* <button
                              onClick={() => { navigate("/book") }}
                              className='btn btn-light f-16 f-mon-reg btn-swip-hhdr '>
                              Appoinment
                            </button> */}
                          </>
                      }
                    </div>
                    // <Link className='f-pop-reg home_link mr-2 ico_pos-rel_headd' to="/signin">
                    //     <PersonIcon className='align-self-center text-white mt-2 me-4' />
                    // </Link>
                  }

                </div>
                <div className='header-small'>
                  <SwipHeader headerLinks={HeaderLinks} />
                </div>
              </div>
            </div>
          </div>
          {/* mobile header ends here */}
        </div>
        <div className="border_bottom_line_shine"></div>

      </div>
    </header>
  );
}

export default Header;
