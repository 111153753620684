import { useEffect, useState, React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ProfileImage } from "../../constant";
import { UpdateProfile } from "../../Redux/Auth/Auth_Action";
import moment from "moment/moment";

const EditProfile = (props) => {

    let curr = new Date();
    curr.setDate(curr.getDate());
    let date = curr.toISOString().substring(0, 10);

    const d = new Date();
    const new_date = new Date().toISOString().slice(0, 10);
    let five_year_restrict = parseInt(new_date?.slice(0, 4));

    const selector = useSelector((state) => state?.Auth);
    // console.log(selector.user, '222222222222222222222222222222222222222');
    const dispatch = useDispatch();
    const [state, setState] = useState({
        first_name: selector?.user?.first_name || "",
        last_name: selector?.user?.last_name || "",
        phone_no: selector?.user?.phone_no || "",
        date_of_birth: selector?.user?.date_of_birth || "",
        email: selector?.user?.email || "",
    });
    const [photo, setUpdateImage] = useState(null);

    const UploadPhoto = (event) => {
        if ((event.target.files[0].size / 1000000) <= 5) {
            setUpdateImage(event.target.files[0]);
            // setSelectedImage(URL.createObjectURL(event.target.files[0]));
        } else {
            toast.error("file size is greater than 5 mb");
        }
    }

    const InuputVal = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    // console.log(photo)

    const UpdateProfileFunc = (e) => {
        e.preventDefault();
        let profile = new FormData();
        profile.append('first_name', state.first_name);
        profile.append('last_name', state.last_name);
        if (photo) {
            profile.append('avatar', photo);
        }
        profile.append('phone_no', state.phone_no);
        profile.append('date_of_birth', state.date_of_birth);

        dispatch(UpdateProfile(profile, selector?.token))
            .then((res) => {
                toast.success("Profile Updated Sucessfully")
            })
            .catch((e) => {
                console.log('gaaaaaaaaaaaaaaa', e)
            })
    }

    return (
        <div className="tab-content Edit_Profile" id="v-pills-tabContent">
            <div
                className="tab-pane fade active show"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
            >
                {/* onClick={() => { props.setChangeTab(1) }} */}
                <p className="main-head">
                    {"Edit Profile"}
                </p>
                <div className="container">
                    <div className="row info-profile">
                        <div className="col-md-12">
                            <div className="text-right">

                                <div className="user-upload">
                                    <div className="left-side">
                                        <div className="img-cont image_edit_prof">
                                            <figure>
                                                {photo ? <img
                                                    src={URL.createObjectURL(photo)}
                                                    alt="image"
                                                    loading="lazy"
                                                    className="image-fluid"
                                                /> : <img
                                                    src={`${selector?.user?.image_url}${selector?.user?.profile_picture}`}
                                                    alt="img"
                                                    className="image-fluid"
                                                    loading="lazy"
                                                />}

                                            </figure>
                                        </div>
                                        <div>
                                            {/* <p className="upload-text1">Photo</p> */}
                                            {/* <p className="upload-text2">
                                            Upload / Change Photo
                                        </p> */}
                                            <div className="porfile_btn_div">
                                                <input className="btn ml-1 upoload_image_profile" onChange={UploadPhoto} type="file" />
                                                <button className="btn upload_profile_pic">Upload Picture </button>
                                            </div>

                                            {/* <button onClick={} className="btn ml-3">Upload</button> */}
                                        </div>
                                    </div>
                                    <div className="right-side">
                                        <div className="text-right">
                                            <button
                                                className="change"
                                                onClick={() => { props.setChangeTab(1) }}
                                            >
                                                View Profile
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Accounts">
                                <div>
                                    <div className=""></div>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="account-form-wrapper">
                                    <form onSubmit={UpdateProfileFunc}>
                                        <div className="form-group row">
                                            <div className="col-lg-6 mb-3">
                                                <input
                                                    // required
                                                    value={state.first_name}
                                                    onChange={InuputVal}
                                                    name="first_name"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="First Name"

                                                />
                                            </div>
                                            <div className="col-lg-6  mb-3">
                                                <input
                                                    // required
                                                    value={state.last_name}
                                                    onChange={InuputVal}
                                                    name="last_name"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Last Name"

                                                />
                                            </div>
                                            <div className="col-lg-6  mb-3">
                                                <input
                                                    required
                                                    value={state.phone_no}
                                                    onChange={InuputVal}
                                                    name="phone_no"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Phone Number"

                                                />
                                            </div>
                                            <div className="col-lg-6  mb-3">
                                                <input
                                                    required
                                                    value={state.email}
                                                    onChange={InuputVal}
                                                    name="email"
                                                    type="email"
                                                    disabled
                                                    className="form-control"
                                                    placeholder="Phone Number"
                                                />
                                            </div>
                                            <div className="col-lg-6  mb-3">
                                                <input
                                                    // required
                                                    value={state.date_of_birth}
                                                    onChange={InuputVal}
                                                    // max={`2023-01-01`}
                                                    max={`${(five_year_restrict - 5).toString()}-12-31`}
                                                    // max={moment().format("DD-MM-YYYY")}
                                                    name="date_of_birth"
                                                    type="date"
                                                    className="form-control datefiled_css_editprofile"
                                                    placeholder="Date of Birth"
                                                />
                                            </div>
                                            {/* <div className="col-lg-6">
                                            <input
                                                required
                                                value={state.e}
                                                onChange={InuputVal}
                                                name="phone_no"
                                                type="text"
                                                className="form-control"
                                                placeholder="Email Address"

                                            />
                                        </div> */}
                                        </div>
                                        <div className="form-group row">


                                            <div className="col-lg-12">
                                                <div className="edit-profile-btn-container">
                                                    <button type="submit"
                                                        className="btn btn-large edit-profile-btn mr-0"

                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProfile;