import logo from "./logo.svg";
import "./App.css";
import "./assets/css/Style.css";
import PublicRoutes from "./routes/PublicRoutes";
import ToastMessages from "./ReuseableComponents/ToastMessage";
import WOW from "wowjs";
import "bootstrap/dist/css/bootstrap.min.css";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.css';

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InfoAfterLoginApiCall, SetSiteSettings, siteSettingApi } from "./Redux/Auth/Auth_Action";
function App() {

  const dispatch = useDispatch();
  const selector = useSelector((state) => state.Auth);
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
    
  }, []);

  const SiteSettingFunc = () => {
    dispatch(siteSettingApi())
      .then((res) => {
        dispatch(SetSiteSettings(res?.data?.response?.data));
        // localStorage.setItem("DREAM_BUILDER_SITESETTINGS", JSON.stringify(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const InfoAfterLoginFunc = () => {
    dispatch(InfoAfterLoginApiCall(selector?.token))
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        dispatch({ type: "DEL_TOKEN" })
      })
  }


  // console.log(selector, '77777777777777777777777777777777777777');

  useEffect(() => {
    SiteSettingFunc();
    InfoAfterLoginFunc();
  }, [])


  return (
    <div className="APP_JS_BC">
      <>
        <div id="loader-full" className="loader-full">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <ToastMessages />
      </>
      <>
        <PublicRoutes />
      </>
    </div>
  );
}

export default App;
